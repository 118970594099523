//##################################################
//############# Popup Menu functions ###############
//##################################################
var timeout = 500;
var closetimer = 0;
var ddmenuitem = 0;
var tinyMCE;
var myobj;
var mouse_x;
var mouse_y;
var msg_ref_id;
var jwplayer;
var id;
var ActiveXObject;
var XHRcontent;
var obj;
var position;
var imagelist;
var search_img;
var objprev;
var objnext;


function show_popmenu(id)
{
      // cancel close timer
      mcancelclosetime();

      // close old layer
      if (ddmenuitem) ddmenuitem.style.visibility = "hidden";

      // get new layer and show it
      ddmenuitem = document.getElementById(id);
      ddmenuitem.style.visibility = "visible";

}
// close showed layer

function mclose()
{
    if(ddmenuitem) ddmenuitem.style.visibility = "hidden";
}

// go close timer
function mclosetime()
{
    closetimer = window.setTimeout(mclose, timeout);
}

// cancel close timer
function mcancelclosetime()
{
  if(closetimer)
  {
    window.clearTimeout(closetimer);
    closetimer = null;
  }
}

// close layer when click-out
document.onclick = mclose;


function validate_article(myform){
   if (myform.title.value===""){
     alert("Title cannot be empty!");
     return false;
   }
   if (myform.active_from.value===""){
       alert("Active From cannot be empty!");
       return false;
   }
   if (tinyMCE.get("content").getContent()===""){
       alert("Article Content cannot be empty!");
       return false;
   }
   return true;
}

function tinyMCESave(){
    tinyMCE.get("content").save();
}

var browserType;

if (document.layers) {browserType = "nn4";}
if (document.all) {browserType = "ie";}
if (window.navigator.userAgent.toLowerCase().match("gecko")) {
   browserType= "gecko";
}

function busy(cursor){
  document.getElementById(cursor).style.display = "";
  return true;
}
window.busy = busy;

function busy_hide(cursor){
  document.getElementById(cursor).style.display = "none";
  return true;
}
window.busy_hide = busy_hide;

//## determine top of screen position
function getScrollY() {
  var scrOfY = 0;
  if( typeof( window.pageYOffset ) == "number" ) {
    //Netscape compliant
    scrOfY = window.pageYOffset;
  }
  else if( document.body && ( document.body.scrollLeft || document.body.scrollTop ) ) {
    //DOM compliant
    scrOfY = document.body.scrollTop;
  }
  else if( document.documentElement &&
      ( document.documentElement.scrollLeft || document.documentElement.scrollTop ) ) {
    //IE6 standards compliant mode
    scrOfY = document.documentElement.scrollTop;
  }
  return scrOfY;
}

function windowWidth(){
   if (window.innerWidth) {return window.innerWidth;}
   if (document.body.clientWidth) {return document.body.clientWidth;}
}

function windowHeight(){
   if (window.innerHeight) {return window.innerHeight;}
   if (document.body.clientHeight) {return document.body.clientHeight;}
}


function isMozCompliant() {
  if(navigator.appVersion.indexOf("MSIE")==-1){
    return true;
  }
  else {return false;}
}


function show_dialog(mydialog){
    myobj=document.getElementById(mydialog);
    myobj.style.width =  windowWidth() - 10 + "px";
    myobj.style.height = "auto"; //windowHeight()*4 + "px";
    myobj.style.top = getScrollY() + "px";
    myobj.style.left = (windowWidth() - myobj.style.width.replace("px","")*1.0)/2-10;
    myobj.style.display="";
    myobj.setAttribute("width",windowWidth() - 10 + "px");
    myobj.setAttribute("height","auto"); //windowHeight()*4 + "px");
    myobj.setAttribute("top",getScrollY() + "px");
    myobj.setAttribute("left",(windowWidth() - myobj.style.width.replace("px","")*1.0)/2);
    myobj.setAttribute("display","");
}
window.show_dialog = show_dialog;

function show_guest_message_dialog(e) {
    mouse_x = mouseX(e);
    mouse_y = mouseY(e);

    myobj=document.getElementById("guest_message_dialog");
    myobj.style.top = mouseY(e) - 40 + "px";
    myobj.style.left = (mouseX(e) + myobj.style.width.replace("px","")*1.0 )/2 + "px";
    myobj.style.display="";
    myobj.setAttribute("top",mouseY(e) - 40 + "px");
    myobj.setAttribute("left",(mouseX(e) + myobj.style.width.replace("px","")*1.0 )/2 + "px");
    myobj.setAttribute("display","");

    msg_ref_id = document.getElementById("message_ref_id");
}
window.show_guest_message_dialog = show_guest_message_dialog;

function hide_dialog(mydialog){
    myobj=document.getElementById(mydialog);
    myobj.style.display="none";
}
window.hide_dialog = hide_dialog;

function window_close(windowobj){
    jwplayer("container").stop();
    windowobj.close();
}
window.window_close = window_close;

function stop_video(){
    myobj=document.getElementById('videoplayer');
    if (myobj != null) myobj.pause();
}
window.stop_video = stop_video;

function filtered_refresh(controller,selected_filter,filter){
   switch(selected_filter[0]){
       case "subject":filter.subject = selected_filter[1];break;
       case "year":filter.year = selected_filter[1];break;
       case "month":filter.month = selected_filter[1];break;
   }
   if (filter.year==="" || filter.year===null) filter.month="";

   var str_url = "/"+controller+"?subject="+filter.subject+"&year="+filter.year+"&month="+filter.month;

   document.location.href=str_url;

}
window.filtered_refresh = filtered_refresh;


//########### Instantiate XHR object #########
var objref = {};
var oldval = "";

function createXMLHttpRequest(){
    var XHR;
    if (window.ActiveXObject) {
        XHR = new ActiveXObject("Microsoft.XMLHTTP");
    }
    else if (window.XMLHttpRequest){
        XHR = new XMLHttpRequest();
    }
return XHR;
}

function load_picofday(){
    if (document.getElementById("picofday") !== null){
        XHRcontent = createXMLHttpRequest();
        XHRcontent.onreadystatechange = display_image;
        XHRcontent.open("GET","/guest/picofday");
        XHRcontent.send(null);
    }
}

function display_image(){
    if (XHRcontent.readyState==4){
        if (XHRcontent.status == 200){
            var results = XHRcontent.responseText;
            var target = document.getElementById("picofday");
            target.innerHTML = results;
        }
    }
}

//###### --- #####

function check_javascript(){
    obj = document.getElementById("nojs_message");
    obj.style.visibility = "hidden";
}

function update_image_nav(id){
  hide("previmage");
  hide("nextimage");
 
  hide_dialog("guest_message_dialog");
 
  imagelist = window.imagelist;
  
  for(position=0; position<imagelist.length;position++){
      search_img = imagelist[position].split("_");
      if (search_img[1]==id) break;
  }

  //update message_ref_id hidden field so we can assign message to image
  msg_ref_id = document.getElementById("image_ref_id");
  msg_ref_id.value = id;
  msg_ref_id.setAttribute("value",id);
  
  //clear message form
  document.getElementById("guest_name").value="";
  document.getElementById("guest_message").value="";

    if (imagelist.length>1) {

      var refobj = document.getElementById("image-"+id);

       if (position>0)
        {
            var previd = imagelist[position-1].split("_");
            objprev = document.getElementById("nav_prev");
            objprev.elements.id.value = previd[1];
            show("previmage");
        }
        if (position<imagelist.length-1)
        {
            var nextid = imagelist[position+1].split("_");
            objnext = document.getElementById("nav_next");
            objnext.elements.id.value = nextid[1];
            show("nextimage");
        }

    }
}
window.update_image_nav = update_image_nav;

function show(id){
   obj = document.getElementById(id);
   obj.setAttribute("display","");
   obj.style.display="";
}

function hide(id){
   obj = document.getElementById(id);
   obj.setAttribute("display","none");
   obj.style.display="none";
}

function mouseX(evt) {
if (evt.pageX) return evt.pageX;
else if (evt.clientX)
   return evt.clientX + (document.documentElement.scrollLeft ?
   document.documentElement.scrollLeft :
   document.body.scrollLeft);
else return null;
}
function mouseY(evt) {
if (evt.pageY) return evt.pageY;
else if (evt.clientY)
   return evt.clientY + (document.documentElement.scrollTop ?
   document.documentElement.scrollTop :
   document.body.scrollTop);
else return null;
}

function validate_guest_message(){
    if (document.getElementById("guest_name").value==="" && document.getElementById("guest_message").value==="")
       alert("Your Name and Message cannot be empty!");
}



